@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'animate.css/animate.min.css';


.text-white {
    --bs-text-opacity: initial !important;
    color: initial !important;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure it takes full width */
  }
  
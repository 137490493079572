.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-page {
    margin: 0 5px;
  }
  
  .pagination-link {
    padding: 5px 10px;
    background-color: #007BFF;
    color: #fff;
    border: 1px solid #007BFF;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .active {
    background-color: #ccc;
  }
  body {
    font-family: "Arial", sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
  }
  
  .portfolio-container {
    max-width: 1200px;
    margin: 30px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .portfolio-title {
    text-align: center;
    color: #333;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .add-funds-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
  }
  
  .add-funds-btn:hover {
    background-color: #45a049;
  }
  
  .portfolio-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .portfolio-table th,
  .portfolio-table td {
    padding: 15px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .portfolio-table th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .edit-btn {
    background-color: #2196f3;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .edit-btn:hover {
    background-color: #1976d2;
  }
  
  .delete-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .delete-btn:hover {
    background-color: #d32f2f;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    list-style: none;
    padding: 0;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination a {
    color: #333;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .pagination a:hover {
    background-color: #4caf50;
    color: white;
  }
  
  .pagination .active a {
    background-color: #4caf50;
    color: white;
  }
  .colorTex{
    font-weight: bold!important;
    color: black !important;
  }  
 /* Default: Mobile View */

/* Desktop View: Use media query for larger screens */
@media (min-width: 768px) {
  .min-h-screen1 {
    min-height: 32vh!important; /* Applied on desktop screens */
  }
}
@media (min-width: 1500px) {
  .min-h-screen1 {
    min-height: 32vh!important; /* Applied on desktop screens */
  }
}

@media (max-width: 400px) {
  .min-h-screen1 {
    min-height: 40vh!important; /* Applied on desktop screens */
  }
}

@media (min-width: 1024px) {
  .min-h-screen1 {
    min-height: 20vh!important; /* Applied on desktop screens */
  }
}


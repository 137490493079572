.Home-market-images {
  transition: all ease 0.8s;
  cursor: pointer;
}

.Home-market-images:hover {
  transform: scale(1.05);
}

.home-portfolio-icons {
  color: #059dc0;
}

.home-portfolio-paragraph {
  color: #64666c;
}

.home-portfolio-cards {
  position: relative;
  overflow: hidden;
}

.home-portfolio-cards::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: top 0.6s ease-in-out, background-color 0.3s ease-in-out;
  opacity: 0;
  cursor: pointer;
}

.home-portfolio-cards:hover::before {
  top: 0;
  opacity: 1;
  background-color: #059dc0;
}

.home-portfolio-cards:hover .home-portfolio-icons,
.home-portfolio-cards:hover .home-portfolio-heading,
.home-portfolio-cards:hover .home-portfolio-paragraph {
  color: white;
}

/*! CSS Used from: https://static.tradingview.com/static/bundles/967.0f60cbbb71814f84f2f2.css */
.scrollButtonCircleLeft-pQro3hO5,.scrollButtonCircleRight-pQro3hO5{fill:#131722;background-color:#fff;border-radius:100%;box-shadow:0 2px 4px #0003;box-sizing:border-box;cursor:pointer;display:none;height:50px;opacity:.8;padding:17px 20px;position:absolute;transition:opacity .35s ease,fill .35s ease;width:50px;}
@media (any-hover:hover){
.scrollButtonCircleLeft-pQro3hO5:hover,.scrollButtonCircleRight-pQro3hO5:hover{fill:#2962ff;opacity:1;}
}
.scrollButtonCircleLeft-pQro3hO5{left:16px;top:50%;transform:translateY(-50%);}
.scrollButtonCircleRight-pQro3hO5{right:16px;top:50%;transform:translateY(-50%);}
.scrollButtonCircleRight-pQro3hO5.isVisible-pQro3hO5{display:block;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/36953.b15d49f57b48cbe20373.css */
.intersectionZone-yQFIuIHW{position:relative;width:0;}
.intersectionDetector-yQFIuIHW{height:100%;position:absolute;}
.left-yQFIuIHW{left:0;}
.right-yQFIuIHW{right:0;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/27781.14fdecabc251c2dd76d9.css */
.container-APMWwIwq{--filmstrip-container-horizontal-offset:var(--adaptive-mf-container-padding);margin:0 calc(var(--filmstrip-container-horizontal-offset)*-1);max-width:calc(100% + var(--filmstrip-container-horizontal-offset)*2);position:relative;width:calc(100% + var(--filmstrip-container-horizontal-offset)*2);}
@media screen and (min-width:1280px){
.container-APMWwIwq{--filmstrip-container-horizontal-offset:unset;}
}
.items-APMWwIwq{display:flex;overflow:scroll;overscroll-behavior-x:contain;scrollbar-width:none;}
.items-APMWwIwq::-webkit-scrollbar{display:none;height:0;width:0;}
.items-APMWwIwq::-webkit-scrollbar-thumb,.items-APMWwIwq::-webkit-scrollbar-track{display:none;}
.items-APMWwIwq::-webkit-scrollbar-corner{display:none;}
.items-APMWwIwq>:not(.internal-APMWwIwq){flex-shrink:0;min-width:var(--filmstrip-item-min-width);width:calc((100% - var(--filmstrip-gap)*(var(--filmstrip-visible-items) - 1))/var(--filmstrip-visible-items));}
.items-APMWwIwq>:not(.internal-APMWwIwq)+:not(.internal-APMWwIwq){margin-left:var(--filmstrip-gap);}
.offsetZone-APMWwIwq{flex:none;width:var(--filmstrip-container-horizontal-offset);}
.scrollBtnWrapper-APMWwIwq{display:none;}
@media screen and (pointer:fine){
.scrollBtnWrapper-APMWwIwq{display:block;}
}
.scrollBtn-APMWwIwq{fill:var(--filmstrip-scroll-btn-fill,#131722);background-color:var(--filmstrip-scroll-btn-bg,#fff);opacity:1;top:var(--filmstrip-scroll-btn-top,50%);z-index:1;}
.leftScrollBtn-APMWwIwq{left:calc(-16px + var(--filmstrip-container-horizontal-offset, 0px));}
.rightScrollBtn-APMWwIwq{right:calc(-16px + var(--filmstrip-container-horizontal-offset, 0px));}
/*! CSS Used from: https://static.tradingview.com/static/bundles/4015.1d0e3a62a59d173c81f3.css */
.tv-circle-logo{align-items:center;border-radius:50%;color:#fff;display:inline-flex;font-style:normal;font-weight:700;justify-content:center;}
.tv-circle-logo--xsmall{font-size:11px;height:24px;line-height:16px;width:24px;}
.tv-circle-logo--medium{font-size:16px;height:36px;line-height:16px;width:36px;}
.tv-circle-logo--xlarge{font-size:40px;height:56px;line-height:40px;width:56px;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/50293.721cbfb1a5d6784e3109.css */
.tv-data-mode{vertical-align:top;}
.tv-data-mode--realtime{color:#42bda8;}
.tv-data-mode--for-ticker-card{display:inline;margin-left:4px;}
.tv-data-mode--realtime.tv-data-mode--for-ticker-card{display:none;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/67103.c7183a76dc0599de9f42.css */
.tv-market-status__dot{border-radius:50%;display:inline-block;height:4px;vertical-align:middle;width:4px;}
.tv-market-status--market{color:#42bda8;}
.tv-market-status--market .tv-market-status__dot{background-color:#42bda8;}
.tv-market-status__dot--for-ticker-card{height:6px;margin-left:4px;width:6px;}
.tv-market-status--market .tv-market-status__dot--for-ticker-card,.tv-market-status__label--for-ticker-card{display:none;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/29005.6605e1e2288a4636ee0c.css */
img{border:none;}
:focus{outline:none;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/65787.b184c8996f9a328ef6b7.css */
p{margin:0;padding:0;}
a{text-decoration:none;}
a:active{outline:0;}
@media (any-hover:hover){
a:hover{outline:0;}
}
button{color:inherit;font:inherit;margin:0;}
button{text-transform:none;}
button{-webkit-appearance:button;cursor:default;}
::selection{background:#bbd9fb;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/967.0f60cbbb71814f84f2f2.css */
.scrollButtonCircleLeft-pQro3hO5,.scrollButtonCircleRight-pQro3hO5{fill:#131722;background-color:#fff;border-radius:100%;box-shadow:0 2px 4px #0003;box-sizing:border-box;cursor:pointer;display:none;height:50px;opacity:.8;padding:17px 20px;position:absolute;transition:opacity .35s ease,fill .35s ease;width:50px;}
@media (any-hover:hover){
.scrollButtonCircleLeft-pQro3hO5:hover,.scrollButtonCircleRight-pQro3hO5:hover{fill:#2962ff;opacity:1;}
}
.scrollButtonCircleLeft-pQro3hO5{left:16px;top:50%;transform:translateY(-50%);}
.scrollButtonCircleRight-pQro3hO5{right:16px;top:50%;transform:translateY(-50%);}
.scrollButtonCircleRight-pQro3hO5.isVisible-pQro3hO5{display:block;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/36953.b15d49f57b48cbe20373.css */
.intersectionZone-yQFIuIHW{position:relative;width:0;}
.intersectionDetector-yQFIuIHW{height:100%;position:absolute;}
.left-yQFIuIHW{left:0;}
.right-yQFIuIHW{right:0;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/27781.14fdecabc251c2dd76d9.css */
.container-APMWwIwq{--filmstrip-container-horizontal-offset:var(--adaptive-mf-container-padding);margin:0 calc(var(--filmstrip-container-horizontal-offset)*-1);max-width:calc(100% + var(--filmstrip-container-horizontal-offset)*2);position:relative;width:calc(100% + var(--filmstrip-container-horizontal-offset)*2);}
@media screen and (min-width:1280px){
.container-APMWwIwq{--filmstrip-container-horizontal-offset:unset;}
}
.items-APMWwIwq{display:flex;overflow:scroll;overscroll-behavior-x:contain;scrollbar-width:none;}
.items-APMWwIwq::-webkit-scrollbar{display:none;height:0;width:0;}
.items-APMWwIwq::-webkit-scrollbar-thumb,.items-APMWwIwq::-webkit-scrollbar-track{display:none;}
.items-APMWwIwq::-webkit-scrollbar-corner{display:none;}
.items-APMWwIwq>:not(.internal-APMWwIwq){flex-shrink:0;min-width:var(--filmstrip-item-min-width);width:calc((100% - var(--filmstrip-gap)*(var(--filmstrip-visible-items) - 1))/var(--filmstrip-visible-items));}
.items-APMWwIwq>:not(.internal-APMWwIwq)+:not(.internal-APMWwIwq){margin-left:var(--filmstrip-gap);}
.offsetZone-APMWwIwq{flex:none;width:var(--filmstrip-container-horizontal-offset);}
.scrollBtnWrapper-APMWwIwq{display:none;}
@media screen and (pointer:fine){
.scrollBtnWrapper-APMWwIwq{display:block;}
}
.scrollBtn-APMWwIwq{fill:var(--filmstrip-scroll-btn-fill,#131722);background-color:var(--filmstrip-scroll-btn-bg,#fff);opacity:1;top:var(--filmstrip-scroll-btn-top,50%);z-index:1;}
.leftScrollBtn-APMWwIwq{left:calc(-16px + var(--filmstrip-container-horizontal-offset, 0px));}
.rightScrollBtn-APMWwIwq{right:calc(-16px + var(--filmstrip-container-horizontal-offset, 0px));}
/*! CSS Used from: https://static.tradingview.com/static/bundles/50999.825fd25585e14301cea1.css */
.card-Gmn0iifS{border-radius:16px;box-sizing:border-box;cursor:pointer;height:auto;padding:8px;}
@media (any-hover:hover){
.card-Gmn0iifS:hover{background-color:#f0f3fa;}
}
.card-Gmn0iifS{outline:none;overflow:visible;position:relative;}
.card-Gmn0iifS:focus{outline:none;}
.card-Gmn0iifS:after{border-style:solid;border-width:2px;box-sizing:border-box;content:"";display:none;height:calc(100% + 8px);left:-4px;pointer-events:none;position:absolute;top:-4px;width:calc(100% + 8px);z-index:1;}
.card-Gmn0iifS:focus:after{display:block;}
.card-Gmn0iifS:focus:not(:focus-visible):after{display:none;}
.card-Gmn0iifS:after{border-color:#2962ffcc;border-radius:20px;}
@media (any-hover:hover){
.card-Gmn0iifS:hover .content-Gmn0iifS{background-color:#f0f3fa;}
}
.content-Gmn0iifS{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:24px;--ui-lib-typography-font-size:20px;fill:#131722;align-items:center;background:#f8f9fd;border-radius:8px;color:#131722;display:flex;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;height:100%;justify-content:center;line-height:var(--ui-lib-typography-line-height);padding:0 20px;text-align:center;}
.content-Gmn0iifS:visited{fill:#131722;color:#131722;}
@media (any-hover:hover){
.content-Gmn0iifS:hover{fill:#2962ff;color:#2962ff;}
}
.content-Gmn0iifS:active{fill:#1848cc;color:#1848cc;}
.content-Gmn0iifS:focus{outline:auto;}
.content-Gmn0iifS:focus:not(:focus-visible){outline:none;}
.arrow-Gmn0iifS{display:inline-block;}
.wrap-Gmn0iifS{white-space:nowrap;}
.text-Gmn0iifS{white-space:pre-wrap;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/80838.e24432a325bf3e2e73ca.css */
.container-gVvYQyhR{align-items:center;border-radius:16px;box-sizing:border-box;color:#131722;display:grid;gap:4px 12px;grid-template-areas:"logo titleContainer quotesContainer" "logo tickerBoxContainer quotesContainer";grid-template-columns:36px 1fr -webkit-min-content;grid-template-columns:36px 1fr min-content;padding:8px;position:relative;z-index:0;}
@media (any-hover:hover){
.container-gVvYQyhR:hover{background-color:#f0f3fa;}
}
@media screen and (min-width:768px){
.container-gVvYQyhR{grid-template-areas:"logo titleContainer" "logo quotesContainer";grid-template-columns:56px 1fr;padding:16px;}
}
.container-gVvYQyhR:active .tickerBox-gVvYQyhR{background-color:#2962ff;color:#fff;}
@media (any-hover:hover){
.container-gVvYQyhR:hover .tickerBox-gVvYQyhR{background-color:#2962ff;color:#fff;}
}
.container-gVvYQyhR:active{background-color:#f0f3fa;}
.container-gVvYQyhR .logoXSmall-gVvYQyhR{display:none;grid-area:logo;}
.container-gVvYQyhR .logoMedium-gVvYQyhR{grid-area:logo;}
@media screen and (min-width:768px){
.container-gVvYQyhR .logoMedium-gVvYQyhR{display:none;}
}
.container-gVvYQyhR .logoLarge-gVvYQyhR{display:none;grid-area:logo;}
@media screen and (min-width:768px){
.container-gVvYQyhR .logoLarge-gVvYQyhR{display:flex;}
}
.titleContainer-gVvYQyhR{display:flex;grid-area:titleContainer;width:-webkit-fit-content;width:fit-content;}
.link-gVvYQyhR{fill:#131722;color:#131722;display:grid;grid-area:link;}
.link-gVvYQyhR:visited{fill:#131722;color:#131722;}
@media (any-hover:hover){
.link-gVvYQyhR:hover{fill:#131722;color:#131722;}
}
.link-gVvYQyhR:active{fill:#131722;color:#131722;}
.link-gVvYQyhR:focus{outline:auto;}
.link-gVvYQyhR:focus:not(:focus-visible){outline:none;}
.link-gVvYQyhR{outline:none;overflow:visible;position:relative;}
.link-gVvYQyhR:focus{outline:none;}
.link-gVvYQyhR:after{border-style:solid;border-width:2px;box-sizing:border-box;content:"";display:none;height:calc(100% + 10px);left:-5px;pointer-events:none;position:absolute;top:-5px;width:calc(100% + 10px);z-index:1;}
.link-gVvYQyhR:focus:after{display:block;}
.link-gVvYQyhR:focus:not(:focus-visible):after{display:none;}
.link-gVvYQyhR:after{border-color:#2962ffcc;border-radius:20px;}
.link-gVvYQyhR:before{bottom:0;content:"";left:0;position:absolute;right:0;top:0;z-index:1;}
.titleContainer-gVvYQyhR .link-gVvYQyhR{position:unset;}
.title-gVvYQyhR{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:24px;--ui-lib-typography-font-size:18px;display:block;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;line-height:var(--ui-lib-typography-line-height);overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
@media screen and (min-width:768px){
.title-gVvYQyhR{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:28px;--ui-lib-typography-font-size:24px;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;line-height:var(--ui-lib-typography-line-height);}
}
.dataMode-gVvYQyhR{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:16px;--ui-lib-typography-font-size:11px;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:400;letter-spacing:.4px;line-height:var(--ui-lib-typography-line-height);text-transform:uppercase;}
.titleContainer-gVvYQyhR .dataMode-gVvYQyhR{font-size:10px;font-weight:600;}
.sessionStatus-gVvYQyhR{align-items:center;display:inline-flex;}
.tickerBoxContainer-gVvYQyhR{display:inline-flex;grid-area:tickerBoxContainer;}
@media screen and (min-width:768px){
.tickerBoxContainer-gVvYQyhR{display:none;}
}
.tickerBox-gVvYQyhR{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:16px;--ui-lib-typography-font-size:12px;background-color:#f0f3fa;border-radius:6px;box-sizing:border-box;color:#131722;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;line-height:var(--ui-lib-typography-line-height);padding:4px 8px;}
.quotesContainer-gVvYQyhR{contain:content;grid-area:quotesContainer;}
.quotes-gVvYQyhR{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:24px;--ui-lib-typography-font-size:18px;align-items:baseline;display:flex;flex-wrap:wrap;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;height:52px;justify-content:flex-end;line-height:var(--ui-lib-typography-line-height);max-width:100%;}
.quotes-gVvYQyhR>:last-child{margin-top:4px;text-align:right;}
@media screen and (min-width:768px){
.quotes-gVvYQyhR{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:24px;--ui-lib-typography-font-size:16px;flex-wrap:nowrap;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;height:24px;justify-content:flex-start;line-height:var(--ui-lib-typography-line-height);margin-top:0;}
.quotes-gVvYQyhR>:last-child{margin-top:0;text-align:initial;}
}
.priceAndCurrency-gVvYQyhR{align-items:baseline;display:flex;flex-wrap:nowrap;}
.last-gVvYQyhR{margin-right:4px;width:-webkit-max-content;width:max-content;}
.currency-gVvYQyhR{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:16px;--ui-lib-typography-font-size:11px;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:400;letter-spacing:.4px;line-height:var(--ui-lib-typography-line-height);text-transform:uppercase;}
.change-gVvYQyhR{margin-left:12px;}
.growing-gVvYQyhR,.up-gVvYQyhR{color:#089981;}
.falling-gVvYQyhR{color:#f23645;}
.timeFrame-gVvYQyhR{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:16px;--ui-lib-typography-font-size:12px;bottom:6px;color:#6a6d78;display:none;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:400;left:0;line-height:var(--ui-lib-typography-line-height);overflow:hidden;padding:0 8px;position:absolute;right:0;text-align:center;text-overflow:ellipsis;white-space:nowrap;}
@media screen and (min-width:768px){
.timeFrame-gVvYQyhR{padding:0 16px;}
}
/*! CSS Used from: https://static.tradingview.com/static/bundles/34766.b657c5134b944ae11410.css */
.container-qBIrfwNj{gap:4px;grid-template-areas:"logo titleContainer" "quotesContainer quotesContainer" "chart chart";grid-template-columns:24px 1fr;padding:12px 12px 20px;}
@media screen and (min-width:768px){
.container-qBIrfwNj{gap:4px 8px;grid-template-columns:36px 1fr;padding:12px;}
}
.container-qBIrfwNj .link-qBIrfwNj{outline:none;overflow:visible;position:static;}
.container-qBIrfwNj .link-qBIrfwNj:focus{outline:none;}
.container-qBIrfwNj .link-qBIrfwNj:after{border-style:solid;border-width:2px;box-sizing:border-box;content:"";display:none;height:calc(100% + 10px);left:-5px;pointer-events:none;position:absolute;top:-5px;width:calc(100% + 10px);z-index:1;}
.container-qBIrfwNj .link-qBIrfwNj:focus:after{display:block;}
.container-qBIrfwNj .link-qBIrfwNj:focus:not(:focus-visible):after{display:none;}
.container-qBIrfwNj .link-qBIrfwNj:after{border-color:#2962ffcc;border-radius:20px;}
@media screen and (min-width:768px){
.container-qBIrfwNj .link-qBIrfwNj{fill:#131722;color:#131722;}
.container-qBIrfwNj .link-qBIrfwNj:visited{fill:#131722;color:#131722;}
@media (any-hover:hover){
.container-qBIrfwNj .link-qBIrfwNj:hover{fill:#1e53e5;color:#1e53e5;}
}
.container-qBIrfwNj .link-qBIrfwNj:active{fill:#1848cc;color:#1848cc;}
.container-qBIrfwNj .link-qBIrfwNj:focus{outline:auto;}
.container-qBIrfwNj .link-qBIrfwNj:focus:not(:focus-visible){outline:none;}
.container-qBIrfwNj .link-qBIrfwNj{outline:none;overflow:visible;position:relative;}
.container-qBIrfwNj .link-qBIrfwNj:focus{outline:none;}
.container-qBIrfwNj .link-qBIrfwNj:after{border-style:solid;border-width:2px;box-sizing:border-box;content:"";display:none;height:calc(100% + 8px);left:-4px;pointer-events:none;position:absolute;top:-4px;width:calc(100% + 8px);z-index:1;}
.container-qBIrfwNj .link-qBIrfwNj:focus:after{display:block;}
.container-qBIrfwNj .link-qBIrfwNj:focus:not(:focus-visible):after{display:none;}
.container-qBIrfwNj .link-qBIrfwNj:after{border-radius:8px;}
}
.container-qBIrfwNj .title-qBIrfwNj{--ui-lib-typography-line-height:22px;--ui-lib-typography-font-size:16px;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;line-height:var(--ui-lib-typography-line-height);}
@media screen and (min-width:768px){
.container-qBIrfwNj .title-qBIrfwNj{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:24px;--ui-lib-typography-font-size:20px;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;line-height:var(--ui-lib-typography-line-height);}
}
.container-qBIrfwNj .last-qBIrfwNj{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:24px;--ui-lib-typography-font-size:16px;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;line-height:var(--ui-lib-typography-line-height);}
.container-qBIrfwNj .logoXSmall-qBIrfwNj{display:flex;}
@media screen and (min-width:768px){
.container-qBIrfwNj .logoXSmall-qBIrfwNj{display:none;}
}
.container-qBIrfwNj .logoMedium-qBIrfwNj{display:none;}
@media screen and (min-width:768px){
.container-qBIrfwNj .logoMedium-qBIrfwNj{display:flex;}
.container-qBIrfwNj .logoLarge-qBIrfwNj{display:none;}
}
.container-qBIrfwNj .quotes-qBIrfwNj{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:18px;--ui-lib-typography-font-size:14px;align-items:flex-start;flex-flow:column nowrap;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;height:46px;justify-content:space-between;line-height:var(--ui-lib-typography-line-height);}
@media screen and (min-width:768px){
.container-qBIrfwNj .quotes-qBIrfwNj{font-feature-settings:"tnum" on,"lnum" on;--ui-lib-typography-line-height:24px;--ui-lib-typography-font-size:16px;font-family:-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;font-size:var(--ui-lib-typography-font-size);font-style:normal;font-weight:600;line-height:var(--ui-lib-typography-line-height);}
}
.container-qBIrfwNj .quotes-qBIrfwNj>:last-child{margin-top:0;}
.container-qBIrfwNj .change-qBIrfwNj{align-items:baseline;display:flex;margin-left:0;width:100%;}
.container-qBIrfwNj .quotesContainer-qBIrfwNj{margin-top:4px;}
.container-qBIrfwNj.inited-qBIrfwNj .timeFrame-qBIrfwNj{bottom:4px;display:block;padding:0 12px;}
@media screen and (min-width:768px){
.container-qBIrfwNj.inited-qBIrfwNj .timeFrame-qBIrfwNj{display:none;}
}
.tickerBoxContainer-qBIrfwNj{display:none;}
.chart-qBIrfwNj{grid-area:chart;}
/*! CSS Used from: https://static.tradingview.com/static/bundles/23136.302a3deb26552dd60b5d.css */
.container-jh7LFL7V{border:1px solid #e0e3eb;}
@media screen and (min-width:768px){
.container-jh7LFL7V{border-width:0;grid-template-areas:"logo titleContainer" "logo quotesContainer";}
}
.container-jh7LFL7V .quotesContainer-jh7LFL7V{margin-bottom:4px;margin-top:unset;}
@media screen and (min-width:768px){
.container-jh7LFL7V .quotesContainer-jh7LFL7V{margin-bottom:0;}
}
.container-jh7LFL7V .quotes-jh7LFL7V{height:42px;}
@media screen and (min-width:768px){
.container-jh7LFL7V .quotes-jh7LFL7V{flex-direction:row;height:24px;}
.container-jh7LFL7V .change-jh7LFL7V{margin-left:12px;}
}
.titleContainer-jh7LFL7V{position:unset;z-index:2;}
@media screen and (min-width:768px){
.titleContainer-jh7LFL7V{position:relative;}
.containerSelected-jh7LFL7V{box-shadow:0 0 2px 0 #e0e3eb,0 2px 24px 0 #e0e3eb;}
}
@media screen and (min-width:768px){
@media (any-hover:hover){
.containerSelected-jh7LFL7V:hover{background-color:initial;}
}
}
.container-jh7LFL7V:not(.containerHidden-jh7LFL7V){margin-left:0!important;}
.container-jh7LFL7V:not(.containerHidden-jh7LFL7V)~.container-jh7LFL7V{margin-left:var(--filmstrip-gap)!important;}
.control-jh7LFL7V{background:#0000;border:none;cursor:default;display:none;grid-area:1/1;outline:none;overflow:visible;padding:0;position:relative;}
.control-jh7LFL7V:focus{outline:none;}
.control-jh7LFL7V:after{border-style:solid;border-width:2px;box-sizing:border-box;content:"";display:none;height:calc(100% + 10px);left:-5px;pointer-events:none;position:absolute;top:-5px;width:calc(100% + 10px);z-index:1;}
.control-jh7LFL7V:focus:after{display:block;}
.control-jh7LFL7V:focus:not(:focus-visible):after{display:none;}
.control-jh7LFL7V:after{border-color:#2962ffcc;border-radius:20px;}
@media screen and (min-width:768px){
.control-jh7LFL7V{display:block;position:unset;}
.control-jh7LFL7V:before{bottom:0;content:"";left:0;position:absolute;right:0;top:0;z-index:1;}
}
.chart-jh7LFL7V{height:46px;margin-left:-12px;margin-right:-5px;}
@media screen and (min-width:768px){
.chart-jh7LFL7V{display:none;}
}
.last-jh7LFL7V{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.priceAndCurrency-jh7LFL7V{width:100%;}
@media screen and (min-width:768px){
.priceAndCurrency-jh7LFL7V{width:auto;}
}
.container-ZQXCYS2e{--filmstrip-item-min-width:152px;--filmstrip-visible-items:3;--filmstrip-gap:12px;--filmstrip-container-horizontal-offset:0px;}
@media screen and (min-width:768px){
.container-ZQXCYS2e{--filmstrip-item-min-width:272px;--filmstrip-visible-items:2;--filmstrip-gap:32px;}
}
@media screen and (min-width:1024px){
.container-ZQXCYS2e{--filmstrip-visible-items:3;}
}
@media screen and (min-width:1280px){
.container-ZQXCYS2e{--filmstrip-item-min-width:280px;}
}
@media screen and (min-width:1440px){
.container-ZQXCYS2e{--filmstrip-visible-items:4;}
}
@media screen and (min-width:1920px){
.container-ZQXCYS2e{--filmstrip-visible-items:6;--filmstrip-gap:32px;}
}
@media screen and (min-width:2560px){
.container-ZQXCYS2e{--filmstrip-gap:64px;}
}
.filmstripItems-ZQXCYS2e{margin:-8px calc(var(--adaptive-mf-container-padding)*-1);padding:8px var(--adaptive-mf-container-padding);}
@media screen and (min-width:768px){
.filmstripItems-ZQXCYS2e{margin:-24px 0 -24px -24px;padding:24px 0 24px 24px;}
}
.seeAllCard-ZQXCYS2e{padding:0;}
.seeAllContent-ZQXCYS2e{border-radius:16px;}
/* Add these styles to your existing stylesheet or component styles */
.modal-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 90%; /* Adjust the width as needed */
  }
  
  .modal-content {
    padding: 12px;
  }
  
  .modal-close {
    /* Add your styles for the close button */
  }
  
  .form-group {
    margin: 10px 0;
  }
  
  .form-group label {
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-actions {
    margin-top: 20px;
    text-align: center;
  }
  
  .form-actions button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-actions button:hover {
    background-color: #0056b3;
  }
  

